/** @jsx jsx */
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Header from '../components/Header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Button, Flex, jsx } from 'theme-ui'
import { GradientBox } from '../components/shared/GradientBox'
import Surfer from '../icons/Surfer'
import ChevronRight from '../icons/chevron-right'

// ContentBlock only intended to be used with this component, just building this to space things out a little
//  and give us some control over each visual block of content on this page.
const ContentBlock = ({ contentTitle, content, isMarkdown = false, image }) => {
  return (
    <Box variant={'cards.primary'} sx={{ m: 3 }}>
      <h3 sx={{ marginTop: '0px' }}>{contentTitle}</h3>
      {isMarkdown ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <p>{content}</p>
      )}
      {image && (
        <GatsbyImage
          image={image.safetyAlertIcon.gatsbyImageData}
          title={image.safetyAlertIconName}
          alt={image.safetyAlertIconDescription}
        />
      )}
    </Box>
  )
}

const AlertTypePage = ({ data }) => {
  const {
    safetyAlertName,
    metaDescription,
    image,
    alertIncident,
    alertPriority,
    capWarningCategory,
    capWarningCertainty,
    capWarningSeverity,
    capWarningResponseType,
    capWarningUrgency,
    description,
    safetyAlertIcon,
    warningInstruction,
  } = data.contentfulAlertTypePage

  return (
    <Layout>
      <SEO
        title={safetyAlertName}
        description={
          metaDescription?.internal?.content || description.description
        }
      />
      <Header
        hideSearch
        headerText={safetyAlertName}
        image={image?.gatsbyImageData}
        imageAltText={image?.description || image?.title}
        imageCred={image?.description}
        height={'30vh'}
      />
      <Container mw={true}>
        <GradientBox
          sx={{
            fontSize: '14px',
            textAlign: 'center',
            padding: '10px',
            fontWeight: 'bold',
            textDecoration: 'none',
            border: 'none',
            my: 30,
            borderRadius: '4px',
          }}
          to={'/state/hawaii'}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Surfer height={35} width={35} /> Want to see live beach conditions
            and alerts?
          </Flex>
          <Button
            sx={{ display: 'flex', alignItems: 'center' }}
            variant="white"
          >
            Go to beaches{' '}
            <ChevronRight
              height={'15px'}
              width={'15px'}
              styles={{
                margin: '0px',
              }}
            />
          </Button>
        </GradientBox>

        <ContentBlock contentTitle={'Alert Incident'} content={alertIncident} />
        <ContentBlock contentTitle={'Alert Priority'} content={alertPriority} />
        <ContentBlock
          isMarkdown={true}
          contentTitle={'Warning Instructions'}
          content={warningInstruction?.childMarkdownRemark?.html}
        />
        <ContentBlock
          contentTitle={'CAP Warning Category'}
          content={capWarningCategory}
        />
        <ContentBlock
          contentTitle={'CAP Warning Certainty'}
          content={capWarningCertainty}
        />
        <ContentBlock
          contentTitle={'CAP Warning Severity'}
          content={capWarningSeverity}
        />
        <ContentBlock
          contentTitle={'CAP Warning Urgency'}
          content={capWarningUrgency}
        />
        <ContentBlock
          contentTitle={'CAP Warning Response Type'}
          content={capWarningResponseType}
        />
        <ContentBlock
          isMarkdown={true}
          contentTitle={'Description'}
          content={description?.childMarkdownRemark?.html}
        />
        <ContentBlock contentTitle={'Icon'} image={safetyAlertIcon} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulAlertTypePage(slug: { eq: $slug }) {
      safetyAlertName
      image {
        title
        description
        gatsbyImageData
      }
      metaDescription {
        internal {
          content
        }
      }
      alertIncident
      alertPriority
      warningInstruction {
        childMarkdownRemark {
          html
        }
      }
      capWarningCategory
      capWarningCertainty
      capWarningSeverity
      capWarningUrgency
      capWarningResponseType
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      safetyAlertIcon {
        safetyAlertIconName
        safetyAlertIconDescription
        safetyAlertIcon {
          gatsbyImageData
        }
      }
    }
  }
`

export default AlertTypePage
